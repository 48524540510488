import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Message } from 'semantic-ui-react'
// Components
import {
  Seo,
  ForgotPasswordModal,
  Primary as SignInButton,
  Loading,
} from '../components'
import { useAuthContext } from '../context/AuthContext'
// Styles
import {
  Wrapper,
  Container,
  SignInTitle,
  StyledForm,
  StyledInput,
  UseEmail,
  StyledSpan,
  ErrorMsg,
  Icon,
  QLogo,
} from '../styles/LoginPage.styles'
// Utils & Services
import { signUpSchema, signInSchema } from '../utils/validations'
import { usePrismic } from '../context/PrismicContext'
import { getUrlParams } from '../utils/enrollmentHelpers'
// Hooks
import useValidateExistingUser from '../hooks/useValidateExistingUser'

const LoginPage = ({ location }) => {
  const { prismicData }: any = usePrismic()

  const {
    title,
    logo,
    google_icon,
    apple_icon,
    facebook_icon,
    sign_in_with,
    use_your_email,
    sign_in,
    signing_in,
    forget_your_password,
    first_time_visiting_q_sciences,
    sign_up,
    sign_up_with,
    already_have_an_account,
    sign_in_error,
    email_placeholder,
    password_placeholder,
    confirm_password_placeholder,
  } = prismicData.prismicLoginPage

  const [isSignUp, setIsSignUp] = useState(false)
  const [isEnrollment, setIsEnrollment] = useState(false)
  const [urlParams, setUrlParams] = useState({})

  useEffect(() => {
    if (!location.search) return
    setUrlParams(getUrlParams(location.search))
    setIsSignUp(true)
    setIsEnrollment(true)
  }, [])

  const {
    firebaseLogin,
    firebaseLoginNewUser,
    firebaseLoginWithProvider,
    isQAccountVerified,
    isUserAuthenticated,
    apiError,
    setApiError,
    isEventSite,
    loading,
  } = useAuthContext()

  const [verifyAccountScreens, step] = useValidateExistingUser()

  const handleSetResolvers = () =>
    isSignUp
      ? { resolver: yupResolver(signUpSchema) }
      : { resolver: yupResolver(signInSchema) }

  const {
    reset,
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm(handleSetResolvers())

  const onSubmit = async (data: { email: string; password: string }) => {
    if (isSignUp) {
      let { error } = await firebaseLoginNewUser(data.email, data.password)
      if (error) reset()
      else
        navigate('/register', {
          state: urlParams,
        })
    } else {
      await firebaseLogin(data.email, data.password)
    }

    reset()
  }

  const handleUseProvider = provider => {
    firebaseLoginWithProvider(provider)
  }

  const handleSignUpSignIn = () => {
    setIsSignUp(!isSignUp)
    setApiError(null)
    setFocus('email')
  }

  const handleErrors = () => (
    <Message
      error
      onDismiss={() => setApiError(null)}
      header={sign_in_error[0].text}
      content={apiError}
    />
  )

  useEffect(() => {
    if (isUserAuthenticated) {
      navigate('/')
    }
  }, [isUserAuthenticated])

  if (loading)
    return (
      <Loading
        loading={true}
        message={signing_in[0].text}
        showLogo={true}
        showBg={isEventSite}
      />
    )

  // TODO - Add logic for determining EXISTING_USER vs NEW_ENROLLMENT
  return (
    <>
      <Seo title={title[0].text} />
      <Wrapper>
        {!isQAccountVerified && !isEnrollment && verifyAccountScreens[step]}
        <Container>
          <QLogo src={logo.url} width="250px" />
        </Container>
        <Container direction="column" maxWidth="500px">
          <SignInTitle>
            {isSignUp ? sign_up_with[0].text : sign_in_with[0].text}
          </SignInTitle>
          <Container justify="space-evenly" padding="1em 0 0 0">
            <div
              style={{ marginLeft: '1.2rem' }}
              onClick={() => handleUseProvider('google')}
            >
              <Icon data-qa="goolge" src={google_icon.url} />
            </div>
            <div
              style={{ marginLeft: '1.2rem' }}
              onClick={() => handleUseProvider('facebook')}
            >
              <Icon data-qa="facebook" src={facebook_icon.url} />
            </div>
            <div
              style={{ marginLeft: '1.2rem' }}
              onClick={() => handleUseProvider('apple')}
            >
              <Icon data-qa="apple" src={apple_icon.url} />
            </div>
          </Container>
        </Container>
        <Container>
          <UseEmail>
            <span>{use_your_email[0].text}</span>
          </UseEmail>
        </Container>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          {!!apiError && handleErrors(errors)}
          <Container direction="column" align="flex-start" maxWidth="500px">
            <ErrorMsg>{errors.email?.message}</ErrorMsg>
            <StyledInput
              {...register('email')}
              placeholder={email_placeholder[0].text}
              autoFocus
              onBlur={() => setApiError(null)}
              data-qa="emailInput"
            />
          </Container>
          <Container direction="column" align="flex-start" maxWidth="500px">
            <ErrorMsg>{errors.password?.message}</ErrorMsg>
            <StyledInput
              {...register('password')}
              placeholder={password_placeholder[0].text}
              type="password"
              data-qa="passwordInput"
            />
          </Container>
          {isSignUp ? (
            <Container direction="column" align="flex-start" maxWidth="500px">
              <ErrorMsg>{errors.passwordConfirm?.message}</ErrorMsg>
              <StyledInput
                {...register('passwordConfirm')}
                placeholder={confirm_password_placeholder[0].text}
                type="password"
                data-qa="passwordConfirmInput"
              />
            </Container>
          ) : null}
          <SignInButton
            type="submit"
            disabled={Object.entries(errors).length !== 0}
            data-qa="submitButton"
          >
            {isSignUp ? sign_up[0].text : sign_in[0].text}
          </SignInButton>
          <Container
            justify="space-between"
            padding="10px 0 0 0"
            maxWidth="500px"
          >
            <span>{forget_your_password[0].text}</span>
            <ForgotPasswordModal data-qa="forgotPassord" resetLogin={reset} />
          </Container>
          <Container justify="space-between" padding="0" maxWidth="500px">
            <span>
              {isSignUp
                ? already_have_an_account[0].text
                : first_time_visiting_q_sciences[0].text}
            </span>
            <StyledSpan data-qa="signUpButton" onClick={handleSignUpSignIn}>
              {isSignUp ? sign_in[0].text : sign_up[0].text}
            </StyledSpan>
          </Container>
        </StyledForm>
      </Wrapper>
    </>
  )
}

export default LoginPage
